<template>
  <el-drawer
    v-model="isShowViewDrawer"
    :show-close="false"
    modal-class="elv-counterparty-overlay"
    class="elv-counterparty-drawer"
    :before-close="onCheckDrawerStatus"
    :size="450"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-counterparty-drawer-header__title">
        {{ t('common.counterparty') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-counterparty-drawer-header__close"
        @click="onCheckDrawerStatus"
      />
    </template>
    <div v-loading="loading">
      <div class="elv-counterparty-drawer-content-title">
        <div class="elv-counterparty-drawer-content-title__type">
          <template v-if="accountStore?.counterpartyDetail?.type !== 'FEE'">
            <SvgIcon :name="counterpartyType?.icon" width="16" height="16" />
            <span class="elv-counterparty-drawer-currency__name">{{ transformI18n(counterpartyType?.label) }}</span>
          </template>
          <template v-else>
            <SvgIcon :name="platformContactData?.icon" width="16" height="16" />
            <span class="elv-counterparty-drawer-currency__name">{{
              capitalize(accountStore.counterpartyDetail?.platform?.type)
            }}</span>
          </template>
        </div>
        <div
          v-if="accountStore?.counterpartyDetail?.type !== 'FEE'"
          class="elv-counterparty-drawer-content-title__operating"
        >
          <el-button link @click="onEditCounterparty">
            <SvgIcon name="sources-edit" width="14" height="14" />
            {{ t('button.edit') }}
          </el-button>
          <div class="elv-counterparty-drawer-content__delete" @click="onDeleteCounterparty">
            <SvgIcon name="sources-delete" width="14" height="14" />
          </div>
        </div>
      </div>
      <div class="elv-counterparty-drawer-content-info">
        <div class="elv-counterparty-drawer-content-name">
          {{
            accountStore?.counterpartyDetail?.type !== 'FEE'
              ? accountStore.counterpartyDetail?.name
              : accountStore.counterpartyDetail?.platform?.name
          }}
        </div>
      </div>
      <template v-if="accountStore?.counterpartyDetail?.type !== 'FEE'">
        <div v-for="(item, index) in accountList" :key="index" class="elv-counterparty-drawer-account">
          <div class="elv-counterparty-drawer-account-title">
            <span>{{ accountTitle(item.type) }}</span>
            <div class="elv-counterparty-drawer-account-add" @click="onOpenAccount('add', item.type)">
              <SvgIcon width="20" height="20" name="add-reports" />{{ t('button.add') }}
            </div>
          </div>
          <ul v-if="item.list?.length" class="elv-counterparty-drawer-account-list">
            <li v-for="account in item.list" :key="account.contactId" class="elv-counterparty-drawer-account-item">
              <div>
                <img
                  v-if="account.platform?.logo"
                  :src="account.platform?.logo"
                  :onerror="useDefaultImage"
                  :alt="account.platform?.name"
                />
                <img
                  v-else
                  src="@/assets/img/default-logo.png"
                  :onerror="useDefaultImage"
                  :alt="account.platform?.name"
                />

                <div
                  class="elv-counterparty-drawer-account-item__identity"
                  :class="{ 'is-bank': item.type === 'BANK' }"
                >
                  <p>{{ formatInputAddress(account.identity) }}</p>
                  <SvgIcon name="sources-copy" width="12" height="12" @click="onClickedCopy(account.identity)" />
                </div>
              </div>
              <div class="elv-counterparty-drawer-account-item-operating">
                <SvgIcon
                  name="sources-edit"
                  width="14"
                  height="14"
                  @click="onOpenAccount('edit', item.type, account)"
                />
                <SvgIcon name="sources-delete" width="14" height="14" @click="onDeleteAccount(account)" />
              </div>
            </li>
          </ul>
        </div>

        <div class="elv-counterparty-drawer-contact-information information">
          <div class="elv-counterparty-drawer-account-title">
            <span>{{ t('title.identificationCode') }}</span>
            <div class="elv-counterparty-drawer-account-add" @click="onOpenInformation('add', {})">
              <SvgIcon width="20" height="20" name="add-reports" />{{ t('button.add') }}
            </div>
          </div>
          <ul
            v-if="accountStore?.counterpartyDetail?.identifications.length"
            class="elv-counterparty-drawer-account-list"
          >
            <li
              v-for="identification in accountStore?.counterpartyDetail?.identifications"
              :key="identification.counterpartyIdentificationId"
              class="elv-counterparty-drawer-account-item"
            >
              <div>
                <div class="elv-counterparty-drawer-account-item__identity is-identification">
                  <p>{{ identification.name }} - {{ identification.code }}</p>
                </div>
              </div>
              <div class="elv-counterparty-drawer-account-item-operating">
                <SvgIcon
                  name="sources-edit"
                  width="14"
                  height="14"
                  @click="onOpenInformation('edit', identification)"
                />
                <SvgIcon
                  name="sources-delete"
                  width="14"
                  height="14"
                  @click="onDeleteIdentificationCode(identification)"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="elv-counterparty-drawer-memo tags">
          <div class="elv-counterparty-drawer-memo-title">{{ t('common.tags') }}</div>
          <el-select
            v-model="fromData.tags"
            multiple
            filterable
            allow-create
            default-first-option
            :reserve-keyword="false"
            :max-collapse-tags="10"
            :remote-method="remoteMethod"
            :loading="tagLoading"
            :placeholder="t('placeholder.inputTag')"
            popper-class="elv-counterparty-dialog-popper"
            @blur="onBlurTags"
          >
            <el-option
              v-for="(item, index) in counterpartyTag"
              :key="index"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="elv-counterparty-drawer-memo">
          <div class="elv-counterparty-drawer-memo-title">{{ t('report.memo') }}</div>
          <el-input
            v-model="fromData.memo"
            :rows="3"
            type="textarea"
            :placeholder="`${t('report.addMemo')}...`"
            @blur="onBlurMemo"
          />
        </div>
      </template>

      <div v-else class="elv-counterparty-drawer-content-platform-info">{{ platformContactData?.info }}</div>
    </div>
  </el-drawer>

  <ContactInformationDialog
    ref="contactInformationRef"
    :model="contactInformationModel"
    :current-data="currentIdentificationData ?? {}"
    @resetDetail="resetDetail"
  />

  <AccountDialog
    ref="accountDialogRef"
    :model="accountDialogType"
    :account-type="accountType"
    :current-data="currentAccountData"
    @resetDetail="resetDetail"
  />

  <CounterpartyDialog
    ref="counterpartyDialogRef"
    model="edit"
    :current-data="props.drawerData"
    @resetList="onResetData"
  />

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :show-cancel-button="true"
    :confirm-button-text="t('button.delete')"
    :cancel-button-text="t('button.cancel')"
    :title="transformI18n(deleteConfirmTitle)"
    :loading="deleteLoading"
    @onConfirmEvent="onConfirmDelete"
    @onCancelEvent="onCancelDeleteDialog"
  >
    <template #content>
      <span class="elv-confirm-counterparty">{{ deleteConfirmInfo }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import AccountsApi from '@/api/AccountsApi'
import { $t, transformI18n } from '@/i18n/index'
import { formatInputAddress } from '@/lib/utils'
import defaultImg from '@/assets/img/default-logo.png'
import { useEntityStore } from '@/stores/modules/entity'
import CounterpartyDialog from './CounterpartyDialog.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { isEmpty, find, uniq, cloneDeep, capitalize } from 'lodash-es'
import { reportContactType, counterpartyTypeOptions } from '@/config/reports'
import AccountDialog from '../../Accounts/Counterparty/components/AccountDialog.vue'
import ContactInformationDialog from '../../Accounts/Counterparty/components/ContactInformationDialog.vue'

const props = defineProps({
  tableType: {
    type: String,
    default: 'counterparty' // counterparty || transaction
  },
  drawerData: {
    type: Object,
    default: () => {}
  }
})

const { t } = useI18n()
const route = useRoute()
const accountDialogRef = useTemplateRef('accountDialogRef')
const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const contactInformationRef = useTemplateRef('contactInformationRef')
const counterpartyDialogRef = useTemplateRef('counterpartyDialogRef')
const loading = ref(true)
const contactInformationModel = ref('add')
const { toClipboard } = useClipboard()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const fromData: any = reactive({
  tags: [],
  memo: ''
})
const isShowViewDrawer = ref(false)
const tagLoading = ref(false)
const deleteLoading = ref(false)
const accountType = ref('')
const counterpartyTag: any = ref([])
const originCounterpartyTag: any = ref([])
const currentAccountData: any = ref({})
const currentIdentificationData: any = ref({})
const accountDialogType = ref('add')
const deleteConfirmType = ref('')
const emit = defineEmits(['resetList', 'onUpdateAccountOrContact'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentCounterpartyData = computed(() => {
  return props.drawerData
})

const counterpartyType: any = computed(() => {
  return find(counterpartyTypeOptions, { value: accountStore.counterpartyDetail?.type })
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const accountTitle = computed(() => {
  return (type: string) => {
    let title = ''
    switch (type) {
      case 'CHAIN':
        title = 'On-Chain address'
        break
      case 'BANK':
        title = 'Bank account'
        break
      case 'EXCHANGE':
        title = 'Exchange account'
        break
      case 'CUSTODY':
        title = 'Custody account'
        break
      case 'PAYMENT':
        title = 'Payment account'
        break
      default:
        break
    }
    return title
  }
})

const accountList = computed(() => {
  return [
    {
      type: 'CHAIN',
      list: accountStore.counterpartyDetail?.contacts?.filter((item: any) => item.platform?.type === 'CHAIN')
    },
    {
      type: 'BANK',
      list: accountStore.counterpartyDetail?.contacts?.filter((item: any) => item.platform?.type === 'BANK')
    },
    {
      type: 'EXCHANGE',
      list: accountStore.counterpartyDetail?.contacts?.filter((item: any) => item.platform?.type === 'EXCHANGE')
    },
    {
      type: 'CUSTODY',
      list: accountStore.counterpartyDetail?.contacts?.filter((item: any) => item.platform?.type === 'CUSTODY')
    },
    {
      type: 'PAYMENT',
      list: accountStore.counterpartyDetail?.contacts?.filter((item: any) => item.platform?.type === 'PAYMENT')
    }
  ]
})

// 删除确认弹窗标题
const deleteConfirmTitle = computed(() => {
  let title = ''
  switch (deleteConfirmType.value) {
    case 'counterparty':
      title = $t('button.deleteCounterparty')
      break
    case 'account':
      title = $t('button.deleteAccount')
      break
    case 'identificationCode':
      title = $t('title.deleteIdentificationCode')
      break
    default:
      break
  }
  return title
})

// 删除确认弹窗信息
const deleteConfirmInfo = computed(() => {
  let title = ''
  switch (deleteConfirmType.value) {
    case 'counterparty':
      title = t('message.deleteCounterpartyInfo', {
        name: accountStore.counterpartyDetail?.name
      })
      break
    case 'account':
      title = t('message.deleteContactInfo', {
        contact: `${accountTitle.value(currentAccountData.value?.platform?.type)} ${formatInputAddress(
          currentAccountData.value?.identity
        )}`
      })
      break
    case 'identificationCode':
      title = t('message.deleteIdentificationCodeInfo', {
        name: `${currentIdentificationData.value?.name} - ${currentIdentificationData.value?.code}`
      })
      break
    default:
      break
  }
  return title
})

// Fee类型对手方信息
const platformContactData = computed(() => {
  let contactData: any = {}
  switch (accountStore.counterpartyDetail?.platform?.type) {
    case 'CHAIN':
      contactData = {
        icon: 'blockchain',
        info: 'System-created Counterparty, used to mark the fees needed to be paid for blockchain transactions.'
      }
      break
    case 'BANK':
      contactData = {
        icon: 'temple',
        info: 'System-created Counterparty, used to denote fees required to be paid to the bank during transactions.'
      }
      break
    case 'EXCHANGE':
      contactData = {
        icon: 'opposite-directions',
        info: 'System-created Counterparty, used to denote fees required to be paid to the exchange during transactions.'
      }
      break
    case 'CUSTODY':
      contactData = {
        icon: 'wallet',
        info: 'System-created Counterparty, used to denote fees required to be paid to the custodial platform during transactions.'
      }
      break
    case 'PAYMENT':
      contactData = {
        icon: 'credit-card',
        info: 'System-created Counterparty, used to denote fees required to be paid to the payment platform during transactions.'
      }
      break
    default:
      break
  }
  return contactData
})

const remoteMethod = async (query: string) => {
  try {
    if (query) {
      tagLoading.value = true
      const { data } = await AccountsApi.getCounterpartTag(entityId.value, { keywords: query })
      tagLoading.value = false
      counterpartyTag.value = data.map((item: any) => {
        return {
          label: item.name,
          value: item.name
        }
      })
    } else {
      counterpartyTag.value = cloneDeep(originCounterpartyTag.value)
    }
  } catch (error) {
    console.log(error)
  }
}

const resetDetail = async () => {
  try {
    loading.value = true
    await accountStore.fetchCounterpartyDetail(entityId.value, currentCounterpartyData.value?.counterpartyId)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const onResetData = () => {
  emit('resetList')
  resetDetail()
}

// 编辑识别代码
const onOpenInformation = (model: string, row: any) => {
  currentIdentificationData.value = row
  contactInformationModel.value = model
  contactInformationRef.value?.onCheckInformationDialog()
}

// 删除确认弹窗
const onDeleteIdentificationCode = async (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentIdentificationData.value = row
  deleteConfirmType.value = 'identificationCode'
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

// 确认删除识别代码
const onConfirmDeleteIdentificationCode = async () => {
  try {
    deleteLoading.value = true
    await AccountsApi.deleteCounterpartyIdentificationCode(
      entityId.value,
      accountStore.counterpartyDetail?.counterpartyId ?? '',
      currentIdentificationData.value?.counterpartyIdentificationId
    )
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    resetDetail()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error?.message)
  } finally {
    deleteLoading.value = false
  }
}

// 打开账户弹窗
const onOpenAccount = (model: string, type: string = 'CHAIN', currentData: object = {}) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentAccountData.value = currentData
  accountDialogType.value = model
  accountType.value = type
  accountDialogRef.value?.onCheckAccountDialog()
}

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

// 编辑对手方
const onEditCounterparty = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  counterpartyDialogRef.value?.onCheckCounterpartyDialog()
}

// 打开删除账户
const onDeleteAccount = async (account: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.delete
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentAccountData.value = account
  deleteConfirmType.value = 'account'
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

// 确认删除账户
const onConfirmDeleteAccount = async () => {
  try {
    deleteLoading.value = true
    await AccountsApi.deleteCounterpartyContact(
      entityId.value,
      accountStore.counterpartyDetail?.counterpartyId ?? '',
      currentAccountData.value?.contactId
    )
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    resetDetail()
  } catch (error: any) {
    ElMessage.error(error?.message)
    console.log(error)
  } finally {
    deleteLoading.value = false
  }
}

const onCheckDrawerStatus = () => {
  isShowViewDrawer.value = !isShowViewDrawer.value
  if (!isShowViewDrawer.value) {
    fromData.tags = []
    fromData.memo = ''
  }
  accountStore.counterpartyDetail = null
}

const onBlurMemo = async () => {
  try {
    loading.value = true
    await AccountsApi.editCounterparty(entityId.value, accountStore.counterpartyDetail?.counterpartyId ?? '', {
      memo: fromData.memo
    })
    ElMessage.success(t('message.editSuccess'))
    emit('resetList')
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const onBlurTags = async () => {
  if (!fromData.tags.length && !accountStore.counterpartyDetail?.tags.length) return
  try {
    loading.value = true
    const params = {
      tags: fromData.tags.map((item: any) => {
        const tag = find(counterpartyTag.value, { name: item })
        if (tag?.counterpartyTagId) {
          return {
            counterpartyTagId: tag?.counterpartyTagId,
            entityId: entityId.value,
            name: tag.name ? tag.name : item
          }
        }
        return {
          counterpartyTagId: 0,
          entityId: entityId.value,
          name: item?.name ? item?.name : item
        }
      })
    }
    await AccountsApi.editCounterparty(entityId.value, accountStore.counterpartyDetail?.counterpartyId ?? '', params)
    ElMessage.success(t('message.editSuccess'))
    emit('resetList')
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const onDeleteCounterparty = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.delete
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  deleteConfirmType.value = 'counterparty'
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

// 取消删除
const onCancelDeleteDialog = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

// 确认删除对手方
const onConfirmDeleteCounterparty = async () => {
  try {
    deleteLoading.value = true
    await AccountsApi.deleteCounterparty(entityId.value, accountStore.counterpartyDetail?.counterpartyId ?? '')
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    emit('resetList')
    onCheckDrawerStatus()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

const onConfirmDelete = () => {
  switch (deleteConfirmType.value) {
    case 'counterparty':
      onConfirmDeleteCounterparty()
      break
    case 'account':
      onConfirmDeleteAccount()
      break
    case 'identificationCode':
      onConfirmDeleteIdentificationCode()
      break
    default:
      break
  }
}

defineExpose({ onCheckDrawerStatus })

watch(
  [() => isShowViewDrawer.value, () => currentCounterpartyData.value],
  async () => {
    if (isShowViewDrawer.value && !isEmpty(currentCounterpartyData.value)) {
      try {
        loading.value = true
        await Promise.all([
          accountStore.fetchCounterpartyDetail(entityId.value, currentCounterpartyData.value?.counterpartyId),
          accountStore.fetchCounterpartTagList(entityId.value)
        ])
        fromData.memo = accountStore.counterpartyDetail?.memo ?? ''
        fromData.tags = accountStore.counterpartyDetail?.tags.map((item: any) => item.name)
        const list = accountStore.counterpartTagList.map((item: any) => {
          return {
            label: item.name,
            value: item.name,
            counterpartyTagId: item.counterpartyTagId
          }
        })
        const defaultTag = reportContactType.map((item: any) => {
          return {
            label: item.label,
            value: item.value,
            counterpartyTagId: ''
          }
        })
        const tagList = [...defaultTag, ...list]
        originCounterpartyTag.value = uniq(tagList)
        originCounterpartyTag.value = originCounterpartyTag.value.filter((item: any) => {
          return (
            item.counterpartyTagId !== '' ||
            !originCounterpartyTag.value.some(
              (otherItem: any) => otherItem.value === item.value && otherItem.counterpartyTagId !== ''
            )
          )
        })
        counterpartyTag.value = cloneDeep(originCounterpartyTag.value)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-counterparty-drawer {
  .elv-counterparty-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-counterparty-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-counterparty-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 24px;
    margin-bottom: 16px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 50px;
  }

  .elv-counterparty-drawer-content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .elv-counterparty-drawer-content-title__type {
      display: flex;

      svg {
        width: 16px;
        height: 16px;
        fill: #aaafb6;
      }

      .elv-counterparty-drawer-currency__name {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #838d95;
        margin-left: 6px;
        margin-right: 8px;
        padding-right: 8px;
      }
    }

    .elv-counterparty-drawer-content-title__operating {
      display: flex;

      .el-button {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
        cursor: pointer;

        svg {
          fill: #838d95;
          margin-right: 6px;
        }

        &:not(.is-disabled):hover,
        &:not(.is-disabled):focus,
        &:not(.is-disabled):active {
          background-color: #fff;
          color: #1343bf;

          svg {
            fill: #1343bf;
          }
        }
      }

      .elv-counterparty-drawer-content__delete {
        cursor: pointer;
        margin-left: 8px;
        padding-left: 8px;
        height: 16px;
        display: flex;
        align-items: center;
        border-left: 1px solid #dde1e6;

        &.is-disabled {
          cursor: not-allowed;
        }

        &:not(.is-disabled):hover {
          svg {
            fill: #1343bf;
          }
        }

        svg {
          fill: #838d95;
        }
      }
    }
  }

  .elv-counterparty-drawer-content-info {
    padding-left: 22px;
    padding-bottom: 14px;
    border-bottom: 1px solid #edf0f3;

    .elv-counterparty-drawer-content-name {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .elv-counterparty-drawer-account,
  .elv-counterparty-drawer-contact-information {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;
    margin: 16px 0;

    .elv-counterparty-drawer-account-title {
      padding: 6px 0px;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .elv-counterparty-drawer-account-add {
        display: flex;
        align-items: center;
        color: #2f63eb;
        font-size: 11px;
        font-weight: 500;
        cursor: pointer;

        svg {
          fill: #7596eb;
        }
      }
    }

    .elv-counterparty-drawer-account-list {
      margin-top: 8px;
      margin-bottom: 8px;
      border-top: 1px solid #edf0f3;

      .elv-counterparty-drawer-account-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #edf0f3;

        > div {
          display: flex;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            display: block;
            margin-right: 8px;
          }

          svg {
            width: 18px;
            height: 18px;
            margin-right: 8px;
            margin-top: -2px;
          }

          .elv-counterparty-drawer-account-item__identity {
            display: flex;
            align-items: center;
            color: #1e2024;
            font-family: 'Barlow';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;

            &.is-bank {
              font-size: 14px;
            }

            svg {
              width: 12px;
              height: 12px;
              margin-left: 4px;
              cursor: pointer;
            }
          }

          .elv-counterparty-drawer-account-item__info {
            width: 300px;
            color: #1e2024;
            font-family: 'Plus Jakarta Sans';
            leading-trim: both;
            text-edge: cap;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }

      .elv-counterparty-drawer-account-item-operating {
        display: flex;

        svg {
          fill: #636b75;
          cursor: pointer;
          margin-right: 8px;

          &:hover {
            fill: #1343bf;
          }

          &.is-disabled {
            cursor: not-allowed;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  .elv-counterparty-drawer-contact-information {
    .elv-counterparty-drawer-account-list {
      .elv-counterparty-drawer-account-item.location {
        > div {
          align-items: baseline;

          svg {
            position: relative;
            transform: translateY(5px);
          }
        }
      }
    }
  }

  .elv-button.is-plain.elv-counterparty-drawer-add-account {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-color: #c8d6fa;
    color: #1753eb;

    svg {
      fill: #1753eb;
    }
  }

  .elv-counterparty-drawer-content-platform-info {
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 16px;
  }

  .elv-counterparty-drawer-memo {
    padding: 8px;
    width: 100%;
    margin-bottom: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;

    .elv-counterparty-drawer-memo-title {
      padding: 6px 0px;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 8px;
    }

    .el-textarea .el-textarea__inner {
      padding: 8px 16px;
    }

    &.tags {
      .el-select__wrapper {
        min-height: 44px;
        height: auto;
      }
    }

    .el-select__selected-item {
      .el-tag {
        padding: 0 16px;
        height: 24px;
        border-radius: 40px;
        border: 1px solid #d0d4d9;
        box-sizing: border-box;
        color: #636b75;
        background-color: #fff;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }
}

.elv-counterparty-drawer-purpose-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 4px 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    width: 124px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #394048;

    &.hover {
      background: #f9fafb;
    }
  }
}

.elv-counterparty-drawer-contact-select {
  width: 100%;
  margin-bottom: 16px;

  .el-input__wrapper {
    padding: 12px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    &:hover {
      border: 1px solid #5e85eb;
      box-shadow: none;
    }

    .el-input__prefix-inner > :last-child {
      margin-right: 10px;
    }
  }
}
</style>
